import { useEffect, useState } from 'react';

import { getThemeStyles } from 'holded/lib/styles';
import { SpacingSection } from 'holded/modules/cms/domain/components/spacing';

import { defaultThemeValues, SpacingTheme } from './SpacingTheme';

const SPACE: Record<string, string> = {
  space1: 'md:h-5',
  space2: 'md:h-10',
  space3: 'md:h-16',
  space4: 'md:h-20',
  space5: 'md:h-32',
};

const SPACE_MOBILE: Record<string, string> = {
  space1: 'h-2',
  space2: 'h-5',
  space3: 'h-8',
  space4: 'h-10',
  space5: 'h-16',
};
const Spacing = ({ showOnMobile, height, background }: SpacingSection) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);
  const space = showOnMobile
    ? `${SPACE_MOBILE[height] ?? 'h-0'} ${SPACE[height] ?? 'h-0'}`
    : `${SPACE[height] ?? 'h-0'}`;

  useEffect(() => {
    const themeStyles = getThemeStyles(SpacingTheme.spacing, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return <div className={`${space} w-full ${themeValues.background}`} />;
};

export default Spacing;
